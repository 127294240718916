a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.tooltip {
}

/* Tooltip text */
.tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: #555;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    /* Position the tooltip text */
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    /* Fade in tooltip */
    opacity: 0;
    transition: opacity 0.3s;
}

    /* Tooltip arrow */
    .tooltip .tooltiptext::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: #555 transparent transparent transparent;
    }

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}


.profileImage {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    font-size: 28px;
    color: #fff;
    text-align: center;
    line-height: 50px;
    margin: 20px 0;
}

.bgcolor1 {
    background: #512DA8;
}
.bgcolor2 {
    background: #2D2DA8;
}
.bgcolor3 {
    background: #2D2D51;
}
.bgcolor4 {
    background: #512D51;
}
.bgcolor5 {
    background: #A82DA8;
}
.bgcolor6 {
    background: #51A8A8;
}
.bgcolor7 {
    background: #51A82D;
}
.bgcolor0 {
    background: #A82D51;
}

.outer {
    padding: 0 20px 0 20px;
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.main {
    display: flex;
    flex-direction: row;
}

.rightdetail {
    width: 410px;
    padding-left: 10px;
}

.briefevent {
    border-top: 2px solid #9B9B9B;
    padding-bottom: 10px;
}

.detailevent {
    border-top: 5px solid #9B9B9B;
    padding-bottom: 15px;
}

.eventheader {
    display: flex;
    justify-content: space-between;
    font-size: 22px;
    font-weight: 500;
}

.eventbigheader {
    display: flex;
    justify-content: space-between;
    font-size: 30px;
    font-weight: 500;
}

.eventcontent {
    font-size: 22px;
    font-weight: 400;
    padding-bottom: 20px;
}

.eventheader.date {
    font-size: 20px;
}

.cardhighlight {
    border-color: #007bff;
    box-shadow: 0 4px 8px rgba(0, 123, 255, 0.4);
}

.personcard {
    border-top: 2px solid #9B9B9B;
    padding-bottom: 10px;
}

/* for event page */
body {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    margin: 0;
    padding: 0;
    background-color: #f4f4f4;
}

.eventheader {
    display: flex;
    align-items: center;
    padding: 10px;
    color: #fff;
    background-color: #333;
    border-bottom: 1px solid #dee2e6;
}

.eventheader-content {
    text-align: center;
    flex-grow: 1;
}

.eventheader h1 {
    margin: 0;
    flex-grow: 1;
    text-align: center;
}

.date {
    font-size: 22px;
}

.event-details, .map-container, .people-involved {
    margin: 20px;
    padding: 12px;
    background-color: #fff;
    border: 1px solid #ddd;
}

    .event-details h2, .map-container h2, .people-involved h2 {
        color: #333;
    }

    .map-container iframe {
        width: 100%;
        max-width: 600px;
        height: 450px;
        border: none;
    }

    .people-involved ul {
        list-style-type: none;
        padding: 0;
    }

    .people-involved li {
        margin-bottom: 10px;
    }

@media (max-width: 768px) {
    .map-container iframe {
        width: 100%;
        height: 300px;
    }
}

.people-involved ul {
    list-style-type: none;
    padding: 0;
}

.people-involved li {
    margin-bottom: 10px;
    display: flex;
    align-items: center; /* Ensures vertical alignment */
}

.detail-link {
    text-decoration: none;
    color: #333;
    font-weight: bold;
    margin-left: 10px; /* Adjust this value as needed to place the icon closer to the text */
    transition: color 0.3s ease; /* Smooth transition for hover effect */
}

.detail-link:hover, .detail-link:focus {
    color: #007BFF;
    text-decoration: none; /* Remove underline to keep it clean with icon */
}

.quick-navigation {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 20px;
    margin: 10px auto;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.quick-navigation-title {
    font-size: 24px;
    color: #333;
    margin-bottom: 10px;
    text-align: left;
}

.round-button {
    background-color: gray;
    color: white;
    border: none;
    border-radius: 50px; /* To make the button fully round */
    padding: 10px 20px; /* Adjust the padding to your preference */
    font-size: 16px; /* Adjust the font size to your preference */
    cursor: pointer; /* Add a pointer cursor on hover */
    display: inline-block;
    text-align: center;
    margin: 2px;
}

.round-button:hover {
    background-color: darkgray; /* Optional: Change background color on hover */
}

.report-button {
    background: none;
    border: none;
    color: #dc3545;
    font-size: 24px;
    cursor: pointer;
    margin-left: 10px;
}

.report-button i {
    margin-left: 5px;
    font-size: 22px; /* Increase icon size */
}

.report-text {
    font-size: 18px;
    color: #dc3545;
}

.notification {
    position: fixed;
    top: 20px;
    right: 20px;
    background-color: #28a745;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}